<template>
    <div class="navigation">
        <!-- <div class="top-buttons">
      <button
        class="nav-btn btn-scale"
        @click="this.$router.push('/companyprofile')"
      > -->
        <!-- <img loading="lazy"  decoding="async"
          src="../assets/Images/icons/button-profile.svg"
          alt="button-profile"
        /> -->
        <!-- <font-awesome-icon :icon="['fas', 'user']" class="nav-icon" />
      </button> -->
        <!-- <button class="nav-btn btn-scale"> -->
        <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/button-dots.svg" alt="button-dots" /> -->
        <!-- <font-awesome-icon :icon="['fas', 'ellipsis']" class="nav-icon" />
      </button>
    </div> -->
        <div class="profile">
            <img loading="lazy" decoding="async" src="../assets/Images/user.png" alt="user" />
            <div class="greeting">Hello {{ this.Store.company_name }}</div>
            <div class="email">{{ userEmail || `GO.Platform@gmail.com` }}</div>
        </div>
        <div class="navigation-buttons">
            <button
                class="gray-hover"
                :class="{ active: selected[0] }"
                @click="
                    () => {
                        selectBtn(0);
                        this.$router.push('/candidates');
                    }
                "
            >
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/group.svg" alt="candidates" /> -->
                <font-awesome-icon :icon="['fas', 'users']" class="nav-icon" />
                <span>Candidates</span>
            </button>
            <button
                class="gray-hover"
                :class="{ active: selected[1] }"
                @click="
                    () => {
                        selectBtn(1);
                        this.$router.push('library-partial');
                    }
                "
            >
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/notes.svg" alt="tasks" /> -->
                <font-awesome-icon :icon="['fas', 'list-check']" class="nav-icon" />
                <span>Assessements</span>
            </button>

            <button
                class="gray-hover"
                :class="{ active: selected[2] }"
                @click="
                    () => {
                        selectBtn(2);
                        this.$router.push('/invoice');
                    }
                "
            >
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/invoice.svg" alt="dashboard" /> -->
                <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" class="nav-icon" />
                <span>Invoice</span>
            </button>
            <button
                class="gray-hover"
                :class="{ active: selected[3] }"
                @click="
                    () => {
                        selectBtn(3);
                        this.$router.push('/archive');
                    }
                "
            >
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/Archeive.svg" alt="dashboard" /> -->
                <font-awesome-icon :icon="['fas', 'box-archive']" class="nav-icon" />
                <span>Archive</span>
            </button>
            <button
                class="gray-hover"
                :class="{ active: selected[4] }"
                @click="
                    () => {
                        selectBtn(4);
                        this.$router.push('/refferal');
                    }
                "
            >
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/referral.svg" alt="notes" /> -->
                <font-awesome-icon :icon="['fas', 'thumbs-up']" class="nav-icon" />
                <span>Refferal</span>
            </button>
            <button
                class="gray-hover"
                :class="{ active: selected[5] }"
                @click="
                    () => {
                        selectBtn(5);
                        this.$router.push('/manage');
                    }
                "
            >
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/build.svg" alt="setting" /> -->
                <font-awesome-icon :icon="['fas', 'wrench']" class="nav-icon" />
                <span>Manage</span>
            </button>
        </div>
        <div class="social">
            <button>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/local_phone.svg" alt="phone" />
            </button>
            <button>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/mail.svg" alt="mail" />
            </button>
            <button>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/chrome_reader_mode.svg" alt="" />
            </button>
            <button>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/ic_telegram.svg" alt="telegram" />
            </button>
            <button>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/ic_whatsapp.svg" alt="whatsapp" />
            </button>
        </div>
    </div>
</template>

<script>
import { useStore } from "../store/index";
import axios from "axios";
export default {
    name: "NavigationView",
    data() {
        return {
            isLoggedIn: false,
            userInitials: "",
            userEmail: "",
            selected: [true, false, false, false, false, false],
            current: 0,
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        selectBtn(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },
    },
    mounted() {
        let token = localStorage.getItem("LoginToken");
        console.log({ token });
        this.isLoggedIn = localStorage.getItem("isLoggedIn");
        if (this.isLoggedIn && token) {
            axios
                .get("https://server.go-platform.com/user/info", {
                    headers: { token: token },
                })
                .then((res) => {
                    console.log({ res });
                    const name = res.data.user.name.split(" ");
                    console.log({ name });
                    const firstName = name[0];
                    const lastName = name[1];
                    const email = res.data.user.email;
                    console.log({ email: res.data.user.email });
                    let initials = lastName.charAt(0) + "." + firstName;
                    this.userInitials = initials.toUpperCase();
                    this.userEmail = email;
                    const user = { firstName, lastName, email };
                    sessionStorage.setItem("user", JSON.stringify(user));
                    //this.$emit('userLogged')
                })
                .catch((err) => {
                    console.log(err);
                    // Handle error if the token is invalid or expired
                    //this.$router.push('/');
                });
            console.log("logged in");
        }
    },
};
</script>

<style scoped>
.navigation {
    width: fit-content;
    margin: 32px;
}

.top-buttons {
    display: flex;
    justify-content: space-between;
}

.profile {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 16px;
    justify-items: center;
    margin: 16px;
    margin-bottom: 32px;
}

.greeting {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 16px;
    color: #6b7a99;
}

.email {
    font-family: "Roboto";
    font-style: normal;
    font-size: 14px;
    color: #adb8cc;
}

.navigation-buttons {
    display: grid;
    grid-template-columns: 50% 50%;
    border-radius: 15px;
    /* overflow: hidden; */
    background-color: #edeff2;
    gap: 2px;
    border: 2px solid #edeff2;
    width: fit-content;
    margin: auto;
}

.navigation-buttons > button {
    background-color: #f4f7fe;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 13px;
    color: #adb8cc;
    width: 96px;
    height: 96px;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
}

.navigation-buttons > button > img {
    margin-bottom: 24px;
}

.social {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    border: 2px solid #edeff2;
    border-radius: 30px;
    margin-top: 64px;
}

.social button {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}

.social button:hover img {
    filter: none;
}

.social img {
    filter: grayscale() hue-rotate(219deg) saturate(23%) brightness(100%);
}

button.active {
    position: static;
    border-radius: 15px;
    transform: scale(1.05);
    background-color: #fff;
    z-index: 9 !important;
    color: #2196f3;
    transform: scale(1.1);
}

.nav-icon {
    font-size: 28px;
}

.active .nav-icon {
    color: #2196f3;
}

.gray-hover:hover {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}

.active:hover {
    background: #fff;
}

/* .active > * {
  transform: scale(0.83);
} */
</style>
