<template>
    <!-- <div class="navbar_wrapper"> -->
    <div ref="navCont" class="Nav_Bar2 w-full flex flex-col gap-0 items-center" :class="{ '-translate-y-64': scrolled, 'translate-y-0': hovered && scrolled }">
        <div class="w-full h-10 p-4 flex flex-row justify-start bg-slate-200">
            <SwitchLanguage />
        </div>
        <div
            :class="{
                whiteNavbar: this.Store.isNavbarWhite,
                'bg-white': this.$route.path !== '/contactUs',
            }"
            class="Nav_Bar flex justify-between items-center px-10 py-4 h-[fit-content] flex-grow relative w-full text-lg font-normal duration-500 ease-in"
        >
            <!-- class="Nav_Bar w-full" -->
            <NavSlide @mouseOut="slideDown" :isDroped="tabHovered == 'products'">
                <ProductDropdown />
            </NavSlide>
            <NavSlide @mouseOut="slideDown" :isDroped="tabHovered == 'ressources'" :xTranslation="`300%`">
                <ResourcesDropdown />
            </NavSlide>
            <logIn :show="login_pop" :toggleModal="toggleModal" :loggedIn="this.loggedIn" :userLoggedIn="this.userLoggedIn" />
            <router-link to="/"
                ><img
                    loading="lazy"
                    :src="this.Store.isNavbarWhite ? require('@/assets/Images/go_logo_blog.svg') : require('@/assets/Images/go_logo.svg')"
                    alt="Recruitable"
                    width="150"
                    height="80"
                    class="thelogo"
            /></router-link>

            <nav class="links text-[18px]">
                <router-link @mouseover="slideDown('products')" ref="anchor" class="anchor flex flex-row justify-between gap-2 items-center" to="/"
                    >{{ $t("Product") }} <font-awesome-icon class="mt-[2px]" :icon="['fas', 'angle-down']" :class="tabHovered == 'products' ? 'rotate-180' : ''"
                /></router-link>
                <router-link class="anchor" to="/planning">{{ $t("Pricing") }}</router-link>
                <router-link ref="anchor" @mouseover="slideDown('ressources')" class="anchor flex flex-row justify-between gap-2 items-center" to="/Talents"
                    >{{ $t("Resources") }} <font-awesome-icon class="mt-[2px]" :icon="['fas', 'angle-down']" :class="tabHovered == 'ressources' ? 'rotate-180' : ''"
                /></router-link>
                <!-- <whiterouter-link class="anchor" to="/blogs">{{ $t("Blogs") }}</whiterouter-link> -->
                <!-- <router-link class="anchor" to="#contact" @click="scrollTo('contact')">{{ $t("Contact") }}</router-link> -->
            </nav>
            <div v-if="isLoggedIn" class="logged_user Nav_btn">
                <button class="logbtn" @click="logOut">Log out</button>
                <span class="initials">{{ this.userInitials }}</span>
            </div>
            <div class="Nav_btn flex justify-end items-center gap-8" v-else>
                <!-- <button class="logbtn">
                    <svg @click="this.toggleSearch" alt="Search Jobs" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            :stroke="this.Store.isNavbarWhite ? '#FFFFFF' : '#343637'"
                            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path :stroke="this.Store.isNavbarWhite ? '#FFFFFF' : '#343637'" d="M20.9999 21L16.6499 16.65" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button> -->
                <button
                    :class="{ whiteNavbar: this.Store.isNavbarWhite }"
                    class="py-2 px-7 shadow rounded text-white bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] font-semibold hover:opacity-[0.8]"
                    @click="toggleModal"
                >
                    {{ $t("Log in") }}
                </button>

                <!-- <button :class="{ whiteNavbar: this.Store.isNavbarWhite }"
                    class="py-2 px-4 rounded-xl text-white bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] border border-[#3db5e6] hover:bg-none hover:text-[#263238] duration-300"
                    @click="toggle">
                    {{ $t("Sign up") }}
                </button>-->
                <!-- <button class="registerbtn">
                <router-link class="a" to="/register">{{ $t("Free with AI") }}</router-link>
            </button> -->
            </div>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
import logIn from "@/components/logIn.vue";
import { useStore } from "../store/index";
import axios from "axios";
import SwitchLanguage from "./SwitchLanguage.vue";
import NavSlide from "./NavSlide.vue";
import ProductDropdown from "./ProductDropdown.vue";
import ResourcesDropdown from "./ResourcesDropdown.vue";
export default {
    name: "NavBar",
    components: { logIn, SwitchLanguage, NavSlide, ProductDropdown, ResourcesDropdown },
    props: {
        userLogged: Function,
        toggleSearch: Function,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    computed: {},
    data() {
        return {
            login_pop: false,
            isLoggedIn: false,
            //userInitials: '',
            userInitials: "",
            observer: null,
            slided: false,
            slideTab: "",
            tabHovered: "",
            scrolled: false,
            hovered: false,
            mousePositions: 0,
        };
    },
    inject: ["userInitials"],

    methods: {
        scrollTo(targetID) {
            document.querySelector(`#${targetID}`).scrollIntoView();
        },
        userLoggedIn() {
            this.userLogged();
        },
        toggleModal() {
            this.$router.push("/login");
            console.log("going to login");
        },
        toggle() {
            this.$router.push("/register");
            console.log("going to register");
        },
        hoverTab(tabName) {
            this.tabHovered = tabName;
        },
        slideDown(tab = "") {
            // console.log('should open', tab)
            this.slideTab = tab;
            this.slided = true;
            this.tabHovered = tab;
            if (this.tabHovered == tab) document.addEventListener("mousemove", this.mouseOutNav);
            else document.removeEventListener("mousemove", this.mouseOutNav);
        },
        mouseOutNav(e) {
            if (this.$refs.NavSlide) {
                if (!this.$refs.NavSlide.contains(e.target)) {
                    this.slided = false;
                    this.tabHovered = "";
                    document.removeEventListener("mousemove", this.mouseOutNav);
                }
            }
        },
        loggedIn() {
            if (this.Store.getisLogged == "user") {
                axios
                    .get("https://server.go-platform.com/user/info", {
                        withCredentials: true,
                    })
                    .then((res) => {
                        console.log({ res });
                        const name = res.data.user.name.split(" ");
                        console.log({ name });
                        const firstName = name[0];
                        const lastName = name[1];
                        const email = res.data.user.email;
                        console.log({ email: res.data.user.email });
                        let initials = firstName.charAt(0) + lastName.charAt(0);
                        this.userInitials = initials.toUpperCase();
                        const user = { firstName, lastName, email };
                        sessionStorage.setItem("user", JSON.stringify(user));
                        //this.$emit('userLogged')
                    })
                    .catch((err) => {
                        console.log(err);
                        // Handle error if the token is invalid or expired
                        //this.$router.push('/');
                    });
            }
        },
        logOut() {
            localStorage.removeItem("LoginToken");
            localStorage.removeItem("isLoggedIn");
            this.isLoggedIn = false;
            this.$router.push("/");
        },
        handleScroll() {
            const scroll = window.scrollY;
            if (scroll > 300) {
                if (this.mousePositions > 200) {
                    this.scrolled = true;
                } else {
                    this.scrolled = false;
                }
            } else {
                this.scrolled = false;
            }
        },
        handleMouseMove(e) {
            this.mousePositions = e.clientY;
            if (e.clientY < 120) {
                if (this.$refs.nav) {
                    if (!this.$refs.navCont.contains(e.target)) {
                        this.hovered = true;
                    }
                }
            } else {
                if (this.$refs.navCont) {
                    if (!this.$refs.navCont.contains(e.target)) {
                        this.hovered = false;
                    }
                }
            }
        },
    },
    mounted() {
        this.Store.observeIntersection();
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("mouseover", this.handleMouseMove);
    },
    updated() {
        this.Store.observeIntersection();
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("mousemove", this.handleMouseMove);
    },
};
</script>

<style lang="scss" scoped>
.navbar_wrapper {
    top: 0;
    // background-color: hsla(72, 0%, 100%, 1);
    // background-image: radial-gradient(
    //     at 0% 51%,
    //     hsla(191, 83%, 80%, 1) 0px,
    //     transparent 50%
    //   ),
    //   radial-gradient(at 99% 55%, hsla(236, 61%, 62%, 1) 0px, transparent 50%);
}

.Nav_Bar2 {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    top: 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.9px);
    -webkit-backdrop-filter: blur(9.9px);
}

.Nav_Bar {
    // position: fixed;
    // z-index: 20;
    // top: 0;
    // // display: flex;
    // // justify-content: space-between;
    // // align-items: center;
    // // padding: 0% 2%;
    // // height: 80px;
    // // flex-grow: 1;
    // top: 0;
    // // margin-bottom: 1rem;
    // // background: transparent;
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    // backdrop-filter: blur(9.9px);
    // -webkit-backdrop-filter: blur(9.9px);
}

/*.blog-signin {
    background: white !important;


}

.blog-a {
    color: #00AEF0 !important;

}*/

.whiteNavbar {
    color: #ffffff !important;
}

.Nav_Bar * {
    font-family: "Roboto";
    // font-style: normal;
    // font-weight: 500;
    // font-size: 20px;
    // line-height: 23px;
}

.links {
    flex-basis: 45%;
    justify-content: space-evenly;
    display: flex;
}

.links .anchor {
    text-decoration: none;
    //color: #263238;
    // font-size: 22px;
    cursor: default;

    &:hover {
        color: #2196f3;
    }
}

.Nav_btn {
    display: flex;
    // justify-content: space-between;
    flex-basis: 22%;
    width: 100%;
    height: 100%;
}

.logbtn {
    // background-color: transparent;
    // border: none;
    color: #263238;
    font-size: 22px;
    cursor: pointer;

    &:hover {
        color: #2196f3;
    }
}

.registerbtn {
    background: #2196f3;
    border-radius: 50px;
    border: none;
    height: fit-content;
    padding: 5% 6%;
    align-self: center;
    cursor: pointer;

    .a {
        text-decoration: none;
        color: #f6f7fc;

        &:hover {
            text-decoration: none;
        }
    }

    transition: 0.2s ease-in-out;

    &:hover {
        transform: scale(0.99);
        box-shadow: 0 2px 10px #0000008a inset;
    }
}

a:hover {
    text-decoration-line: underline;
}

.logged_user {
    justify-content: center;
    align-items: center;

    .initials {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background-color: #4d5e80;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 30px;
    }
}
</style>
