<template>
    <div v-if="showNotif" class="notification-wrapper">
        <div class="exit-notification" @click="toggleNotif"></div>
        <div class="notification-container w-full lg:w-1/3 right-0 lg:-right-[65%] top-32 lg:top-16">
            <span>Notifications:</span>
            <div v-if="notifications.length === 0">{{ $t("No notifications") }}</div>
            <div v-else class="notification-list">
                <div class="notification-item" v-for="(notification, index) in notifications" :key="index">
                    <font-awesome-icon class="notification-icon" :icon="['fas', 'bell']" />
                    <span class="notification-title">{{ notification.title }}</span>
                    <span class="notification-description">{{ notification.description }}</span>
                    <span class="notification-date">{{ notification.date }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotificationWindow",
    props: ["showNotif", "toggleNotif"],
    data() {
        return {
            notifications: [
                {
                    title: "Task Updated",
                    description: "This is a notification description",
                    date: new Date("2022/05/23").toLocaleDateString("en-GB"),
                    icon: "",
                },
                {
                    title: "Task Updated",
                    description: "This is a notification description",
                    date: new Date("2022/05/23").toLocaleDateString("en-GB"),
                    icon: "",
                },
                {
                    title: "Task Updated",
                    description: "This is a notification description",
                    date: new Date("2022/05/23").toLocaleDateString("en-GB"),
                    icon: "",
                },
                {
                    title: "Task Updated",
                    description: "This is a notification description",
                    date: new Date("2022/05/23").toLocaleDateString("en-GB"),
                    icon: "",
                },
                {
                    title: "Task Updated",
                    description: "This is a notification description",
                    date: new Date("2022/05/23").toLocaleDateString("en-GB"),
                    icon: "",
                },
                {
                    title: "Task Updated",
                    description: "This is a notification description",
                    date: new Date("2022/05/23").toLocaleDateString("en-GB"),
                    icon: "",
                },
                {
                    title: "Task Updated",
                    description: "This is a notification description",
                    date: new Date("2022/05/23").toLocaleDateString("en-GB"),
                    icon: "",
                },
                {
                    title: "Task Updated",
                    description: "This is a notification description",
                    date: new Date("2022/05/23").toLocaleDateString("en-GB"),
                    icon: "",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.notification-wrapper {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.exit-notification {
    position: absolute;
    //width: 100%; height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.notification-container {
    position: relative;
    //top: calc(100px - 16px);
    // left: 65%;
    height: fit-content;
    background: #fff;
    max-height: 50%;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 3px 3px 20px 10px rgba(0, 0, 0, 0.08);
    display: flex;
    gap: 0.5rem;
    flex-direction: column;

    > :first-child {
        color: #344050;
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.5rem;

        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background: #edf2f8;
        }
    }

    .notification-list {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        gap: 0.75rem;

        .notification-item {
            display: grid;
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: 10% 1fr 30%;
            position: relative;
            padding-bottom: 0.25rem;

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                background: #edf2f8;
                position: absolute;
                bottom: 0;
            }

            .notification-icon {
                grid-column: 1;
                grid-row: 1 / span 2;
                font-size: 36px;
                //width: 50%; height: 75%;
                color: #c3cad9;
            }

            .notification-title {
                grid-column: 2;
                grid-row: 1;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                padding-left: 0.5rem;
            }

            .notification-description {
                grid-column: 2;
                grid-row: 2;
                font-weight: 400;
                font-size: 13px;
                line-height: 19px;
                color: #5c5f62;
                padding-left: 0.5rem;
            }

            .notification-date {
                grid-column: 3;
                font-weight: 400;
                font-size: 13px;
                line-height: 19px;
                color: #5c5f62;
                text-align: end;
            }
        }
    }
}

::-webkit-scrollbar {
    display: none;
}
</style>
