<template>
    <div class="tab-content" v-show="tab === selectedTab">
        <slot></slot>
    </div>
</template>

<script>
import { inject } from "vue";
export default {
    name: "NavTab",
    props: ["tabTitle"],
    setup() {
        const selectedTab = inject("selectedTab");

        return {
            selectedTab,
        };
    },
    mounted() {
        // console.log("selected test", this.type == this.selectedType);
    },
};
</script>

<style lang="scss" scoped></style>
