<template>
    <div
        v-show="isDroped"
        @mouseleave="$emit('mouseOut')"
        class="w-fit h-fit bg-[#fff] shadow-[0_10px_15px_0_rgba(0,0,0,15%)] absolute left-0 bottom-0 translate-y-full"
        ref="NavSlide"
        :style="{ transform: `translateX(${xTranslation}) translateY(100%)` }"
    >
        <slot></slot>
    </div>
</template>

<script>
import { useStore } from "@/store";
export default {
    name: "NavSlide",
    setup() {
        const store = useStore();
        return { store };
    },
    props: {
        isDroped: { type: Boolean, default: false },
        xTranslation: { type: String, default: "50%" },
    },
};
</script>

<style lang="scss" scoped></style>
