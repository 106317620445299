<template>
    <div class="Mobile_Nav_Bar">
        <logIn :show="login_pop" :toggleModal="toggleModal" @loggedIn="loggedIn" :userLoggedIn="this.userLoggedIn" />
        <router-link to="/"><img loading="lazy" decoding="async" src="../assets/Images/go_logo.svg" alt="Recruitable" width="75" height="55" class="thelogo w-28" /></router-link>
        <div>
            <button class="logbtn search_btn">
                <img loading="lazy" decoding="async" src="../assets/Images/search_icon.svg" alt="Search Jobs" height="30" width="30" @click="this.toggleSearch" />
            </button>
            <button class="logbtn">
                <img loading="lazy" decoding="async" src="../assets/Images/hamburger-icon.svg" alt="toggle navbar" height="35" width="35" @click="this.toggleNavBar" />
            </button>
        </div>
        <div v-show="this.phoneNav">
            <div class="mobile_Nav">
                <div class="close_nav_btn">
                    <div class="bottom-logo">
                        <router-link to="/" class="logo"
                            ><img loading="lazy" decoding="async" src="../assets/Images/go_logo.svg" alt="Recruitable" width="75" height="55" class="thelogo w-28" />
                            <!-- Go Platform -->
                        </router-link>
                    </div>
                    <button class="logbtn">
                        <img loading="lazy" decoding="async" src="../assets/Images/close-nav.svg" alt="toggle navbar" height="35" width="35" @click="this.toggleNavBar" />
                    </button>
                </div>
                <nav class="Mobile_links">
                    <router-link class="anchor" to="/" @click="this.toggleNavBar">
                        {{ $t("Home") }}
                        <img loading="lazy" decoding="async" src="../assets/Images/right_arrow.svg" alt="toggle navbar" height="24" width="24" />
                    </router-link>
                    <router-link class="anchor" to="/Talents" @click="this.toggleNavBar"
                        >{{ $t("Talents") }}<img loading="lazy" decoding="async" src="../assets/Images/right_arrow.svg" alt="toggle navbar" height="24" width="24"
                    /></router-link>
                    <router-link class="anchor" to="/planning" @click="this.toggleNavBar"
                        >{{ $t("Pricing") }}<img loading="lazy" decoding="async" src="../assets/Images/right_arrow.svg" alt="toggle navbar" height="24" width="24"
                    /></router-link>
                    <router-link class="anchor" to="/articles" @click="this.toggleNavBar"
                        >{{ $t("Blogs") }}<img loading="lazy" decoding="async" src="../assets/Images/right_arrow.svg" alt="toggle navbar" height="24" width="24"
                    /></router-link>
                    <router-link class="anchor" to="#" @click="CloseScroll('contact')"
                        >{{ $t("Contact") }}<img loading="lazy" decoding="async" src="../assets/Images/right_arrow.svg" alt="toggle navbar" height="24" width="24"
                    /></router-link>
                </nav>
                <div v-if="isLoggedIn" class="logged_user Nav_btn">
                    <button class="logbtn" @click="logOut">{{ $t("Log out") }}</button>
                    <span class="initials">{{ this.userInitials }}</span>
                </div>
                <div class="mobile_Nav_btn" v-else>
                    <button class="mobile_logbtn" @click="toggleModal">{{ $t("Log in") }}</button>
                    <button class="mobile_registerbtn">
                        <router-link class="a" to="/register" @click="this.toggleNavBar"> {{ $t("Register Now") }}</router-link>
                    </button>
                </div>
            </div>
            <div class="dark-side" @click="toggleNavBar"></div>
        </div>
    </div>
</template>

<script>
import logIn from "@/components/logIn.vue";
import axios from "axios";
export default {
    name: "PhoneNavBar",
    components: { logIn },
    props: {
        userLogged: Function,
        toggleSearch: Function,
    },
    data() {
        return {
            login_pop: false,
            isLoggedIn: false,
            phoneNav: false,
            //userInitials: '',
            userInitials: "",
        };
    },
    inject: ["userInitials"],
    methods: {
        CloseScroll(targetID) {
            this.toggleNavBar();
            this.scrollTo(targetID);
        },
        scrollTo(targetID) {
            document.querySelector(`#${targetID}`).scrollIntoView();
        },
        userLoggedIn() {
            this.userLogged();
        },
        toggleModal() {
            this.phoneNav = false;
            this.$router.push("/login");
        },
        toggleNavBar() {
            this.phoneNav = !this.phoneNav;
        },
        loggedIn() {
            let token = localStorage.getItem("LoginToken");
            console.log({ token });
            this.isLoggedIn = localStorage.getItem("isLoggedIn");
            if (this.isLoggedIn && token) {
                axios
                    .get("https://server.go-platform.com/user/info", {
                        headers: { token: token },
                    })
                    .then((res) => {
                        console.log({ res });
                        const name = res.data.user.name.split(" ");
                        console.log({ name });
                        const firstName = name[0];
                        const lastName = name[1];
                        const email = res.data.user.email;
                        console.log({ email: res.data.user.email });
                        let initials = firstName.charAt(0) + lastName.charAt(0);
                        this.userInitials = initials.toUpperCase();
                        const user = { firstName, lastName, email };
                        sessionStorage.setItem("user", JSON.stringify(user));
                        //this.$emit('userLogged')
                    })
                    .catch((err) => {
                        console.log(err);
                        // Handle error if the token is invalid or expired
                        //this.$router.push('/');
                    });
                console.log("logged in");
            }
        },

        logOut() {
            localStorage.removeItem("LoginToken");
            localStorage.removeItem("isLoggedIn");
            this.isLoggedIn = false;
            this.$router.push("/");
        },
    },
    mounted() {
        this.loggedIn();
    },
};
</script>

<style lang="scss" scoped>
.Mobile_Nav_Bar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 50;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0% 2%;
    height: 80px;
    flex-grow: 1;
    margin-bottom: 1rem;
    background: transparent;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.9px);
    -webkit-backdrop-filter: blur(9.9px);
}

.dark-side {
    // display: none;
    // top: 0;
    // left: 0;
    // width: 100vw;
    // height: 100vh;
    // background-color: rgba(0, 0, 0, 0.5);
    // z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    width: 90vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
}

.mobile_Nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    width: 90vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    gap: 3rem;

    .anchor {
        padding: 1rem 0rem;
    }
}

.Nav_Bar * {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
}

.Mobile_links {
    flex-basis: 25%;
    // margin-top: -80%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    @media (min-width: 768px) and (max-width: 991.98px) {
        // flex-direction: column-reverse;
        flex-basis: 35%;
        margin-top: -40%;
    }
}

.Mobile_links .anchor {
    width: 100%;
    text-decoration: none;
    color: black;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: 768px) and (max-width: 991.98px) {
        // flex-direction: column-reverse;
        font-size: 24px;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 16px;
        font-weight: 150;
        line-height: 1.1428571429;
        letter-spacing: 0.007em;
        font-family:
            SF Pro Display,
            SF Pro Icons,
            Helvetica Neue,
            Helvetica,
            Arial,
            sans-serif;
    }
}

.mobile_Nav_btn {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-basis: 20%;
    width: 100%;
    height: 100%;
    gap: 2rem;

    @media (min-width: 768px) and (max-width: 991.98px) {
        // flex-direction: column-reverse;
        flex-basis: 25%;
    }
}

.mobile_logbtn {
    background-color: #ffffff;
    color: #5a7fff;
    border: 2px solid #5a7fff;
    cursor: pointer;

    &:hover {
        color: #f6f7fc;
        background-color: #5a7fff;
    }

    border-radius: 10px;
    height: fit-content;
    width: 70%;
    padding: 1rem;
    align-self: center;

    .a {
        text-decoration: none;
        color: #4d5e80 !important;

        &:hover {
            text-decoration: none;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        // flex-direction: column-reverse;
        font-size: 24px;
        padding: 4% 2%;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 18px;
        font-weight: 600;
    }
}

.logbtn {
    background-color: transparent;
    border: none;
    color: #4d5e80;
    cursor: pointer;

    &:hover {
        color: #2196f3;
    }
}

.mobile_registerbtn {
    background: #5a7fff;
    border-radius: 10px;
    border: none;
    height: fit-content;
    width: 70%;
    padding: 1rem;
    align-self: center;

    .a {
        text-decoration: none;
        color: #f6f7fc !important;

        &:hover {
            text-decoration: none;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        // flex-direction: column-reverse;
        font-size: 28px;
        padding: 4% 2%;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 18px;
        font-weight: 600;
    }
}

a:hover {
    text-decoration-line: underline;
}

.logged_user {
    justify-content: center;
    align-items: center;

    .initials {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background-color: #4d5e80;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 30px;
    }
}

.close_nav_btn {
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 3% 0% 1% 1%;

    img {
        @media (min-width: 768px) and (max-width: 991.98px) {
            // flex-direction: column-reverse;
            height: 50px;
            width: 50px;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
        }
    }

    button {
        border: 0.5px solid #4d5e80;
        border-radius: 10px;
        padding: 0;
        height: fit-content;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    }
}

.bottom-logo {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2% 2% 4% 0%;

    .logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #fff;
        text-decoration: none;
        font-size: 14px;
        font-weight: 700;

        @media (min-width: 768px) and (max-width: 991.98px) {
            // flex-direction: column-reverse;
            font-size: 24px;
            // padding: 4% 2%;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            img {
                width: 8rem;
                height: fit-content;
            }
        }
    }
}
</style>
