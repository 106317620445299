<template>
    <nav class="w-full bg-white px-1 py-2 rounded-md flex flex-row items-center justify-start gap-5">
        <span
            v-for="tab in tabs"
            :key="tab"
            class="text-lg font-semibold rounded-sm p-4"
            @click="selectedTab = tab"
            :class="tab === selectedTab ? 'bg-[rgb(125,143,179,0.6)] text-white' : 'text-[#7d8fb3] bg-white'"
        >
            {{ tab }}
        </span>
    </nav>
</template>

<script>
import { ref, provide } from "vue";
export default {
    name: "NavTabcontainer",
    data() {},
    setup(props, { slots }) {
        const tabs = ref(slots.default().map((tab) => tab.props.tabTitle));
        const selectedTab = ref(tabs.value[0]);

        provide("selectedTab", selectedTab);
        return { tabs, selectedTab };
    },
};
</script>

<style lang="scss" scoped></style>
