<template>
    <div class="EmailWindow" v-if="showEmail">
        <div class="exitArea" @click="toggleEmailWindow"></div>
        <div class="emailFormWrapper flex justify-center items-center rounded">
            <div class="fixed top-0 left-0 w-full h-full" v-if="showPlans" @click="toggleDropdown"></div>

            <div class="emailForm flex flex-col gap-[25px]" v-if="step === 1">
                <div class="relative">
                    <input v-model="name" type="text" :placeholder="$t('Your Name')" />
                    <div class="err_msg" v-if="this.requiredFields.name">{{ this.requiredFields.name }}</div>
                </div>
                <div class="relative">
                    <input v-model="email" type="email" :placeholder="$t('Your Email')" />
                    <div class="err_msg" v-if="this.requiredFields.email">{{ this.requiredFields.email }}</div>
                </div>
                <div class="w-full flex items-center justify-between">
                    <span class="w-[30%] text-left"> Selected Plan: </span>
                    <div class="custom-select w-[90%]" style="z-index: 3" @click="toggleDropdown">
                        <div class="selected-option flex justify-between items-center">
                            {{ plan }}
                            <font-awesome-icon :icon="['fas', 'chevron-down']" />
                        </div>
                        <ul class="options" v-if="showPlans">
                            <li
                                v-for="(plan, index) in plans"
                                :key="index"
                                @click.stop
                                @click="
                                    () => {
                                        toggleDropdown();
                                        selectOption(plan);
                                    }
                                "
                            >
                                {{ plan }}
                            </li>
                        </ul>
                    </div>
                </div>
                <textarea v-model="message" style="resize: none" rows="5" id="messageTextarea" name="message" placeholder="Message"></textarea>
                <button class="btn-contact" @click="sendContactUs" v-if="!loading" type="submit">
                    {{ $t("Request") }}
                    <font-awesome-icon class="arrow-right" icon="fa-solid fa-arrow-right" />
                </button>
                <button class="btn-contact btn-loading" v-else>
                    <LoaderComponent />
                </button>
            </div>
            <div v-else>
                <span class="RefferalView"> Thank you for contacting us!</span> <br />
                <span class="text">
                    We will get back to you as soon as possible. <br />
                    You can still try our platform for free while we process your request. <br />
                </span>
                <button class="btn-contact" style="margin-top: 5%" @click="toggleEmailWindow">{{ $t("Close") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "@/components/LoaderComponent.vue";

export default {
    name: "PlanningContact",
    components: { LoaderComponent },
    props: {
        showEmail: Boolean,
        toggleEmailWindow: Function,
        selectedPlan: String,
    },
    data() {
        return {
            plans: ["Basic - Mounthly", "Basic - Yearly", "Pro - Mounthly", "Pro - Yearly", "Entreprise - Mounthly", "Entreprise - Yearly"],
            showPlans: false,
            loading: false,
            requiredFields: {
                name: false,
                email: false,
                message: false,
            },
            name: "",
            email: "",
            message: "",
            plan: this.selectedPlan,
            step: 1,
        };
    },
    watch: {
        selectedPlan(newVal) {
            console.log({ newVal });
            this.plan = newVal;
        },
    },
    methods: {
        toggleDropdown() {
            this.showPlans = !this.showPlans;
        },
        selectOption(plan) {
            this.plan = plan;
        },
        checkValidity() {
            this.requiredFields = {
                name: "",
                email: "",
                message: "",
            };
            const emailRegex = /^\S+@\S+\.\S+$/; // Regular expression for basic email format
            let isValid = true;

            if (!emailRegex.test(this.email)) {
                this.requiredFields.email = "Please enter a valid email address.";
                isValid = false;
            }
            if (this.name === "") {
                this.requiredFields.name = "Please enter your name.";
                isValid = false;
            }

            return isValid;
        },
        sendContactUs() {
            if (this.checkValidity()) {
                this.loading = true;
                let contactForm = {
                    email: this.email,
                    name: this.name,
                    plan: this.plan,
                    message: this.message,
                };
                axios
                    .post("https://server.go-platform.com/requestPlan", contactForm, {
                        withCredentials: true,
                    })
                    .then(() => {
                        this.step = 2;
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.email = "";
                        this.name = "";
                        this.title = "Report a bug";
                        this.message = "";
                    });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.EmailWindow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: #0000003a;
    display: flex;
    justify-content: center;
    align-items: center;

    .exitArea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .emailFormWrapper {
        position: relative;
        z-index: 2;
        width: 50%;
        height: 60%;
        background-color: #fff;
        border-radius: 10px;
    }

    input {
        width: 55%;
        padding: 1rem;
        border-radius: 10px;
        //  outline-color: #2196f3;
        background-color: #fff;
        outline: none;
        box-shadow:
            0 2px 4px rgba(0, 0, 0, 0.1),
            0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .emailForm {
        width: 60%;
        height: 80%;

        input,
        textarea,
        select,
        .select-container {
            width: 100%;
        }
    }

    textarea {
        width: 55%;
        padding: 1rem;
        border-radius: 10px;
        background-color: #fff;
        // outline-color: #2196f3;
        box-shadow:
            0 2px 4px rgba(0, 0, 0, 0.1),
            0 4px 8px rgba(0, 0, 0, 0.1);

        outline: none;
    }

    select {
        width: 100%;

        cursor: pointer;

        outline: none;
    }

    .btn-contact {
        width: 100%;
        height: 60px;
        padding: 1rem;
        border-radius: 10px;
        border: none;
        background: #2196f3;
        font-weight: 500;
        color: #fff;
        box-shadow:
            0 2px 4px rgba(0, 0, 0, 0.1),
            0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .btn-contact:hover {
        opacity: 0.85;
    }

    .btn-loading {
        opacity: 0.85;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-select {
        position: relative;
        // width: 200px;
        border-bottom: 1px solid #ccc;
        border-radius: 4px;
        cursor: pointer;
    }

    .selected-option {
        padding: 8px;
    }

    .options {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        border-bottom: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 4px 4px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .options li {
        padding: 8px;
        cursor: pointer;
        border-bottom: 1px solid #ccc;
        text-align: left;
    }

    .options li:hover {
        background-color: #f0f0f0;
    }

    .err_msg {
        color: #ff6969;
        font-size: 14px;
        font-weight: 300;
        position: absolute;
        top: 100%;
    }

    .RefferalView {
        font-size: 24px;
        font-weight: 500;
        margin-top: 5%;
        color: #0f172a;
    }

    .text {
        font-size: 18px;
        font-weight: 300;
        margin-top: 5%;
        color: #0f172a;
    }
}
</style>
