<template>
    <div class="h-full w-full mt-[10vh] md:mt-[10vh] relative overflow-hidden">
        <div class="absolute left-0 -top-10 z-[-11] rotate-20">
            <svg width="900" height="820" viewBox="0 0 900 820" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    opacity="0.4"
                    d="M719.001 226.5C814.601 192.5 913.168 10.6667 897.501 -68L-109.5 -9.5L-141 820C-78.1666 805.833 29.7069 799 107.5 705C235.776 550 362 648 431.5 580C501 512 477 448 500.5 365C524 282 599.501 269 719.001 226.5Z"
                    fill="#EBF3FF"
                />
            </svg>
        </div>
        <div class="flex flex-col md:flex-row items-center justify-between px-[5%]">
            <div class="text-center flex flex-col justify-between md:text-left">
                <h1 class="text-[72px] text-slate-700 z-3 font-[700] mt-[8%]">
                    Hire Now, Pay
                    <span class="relative overflow-visible whitespace-nowrap">
                        <div class="-rotate-[6deg] bg-[#FBBC05] absolute w-full h-full top-0 left-0 -z-10"></div>
                        Later.</span
                    >
                </h1>
                <p class="text-[#8E939C] my-8 text-[18px] w-full md:w-[75%]">
                    Sed placerat convallis aenean fermentum. Aliquet eget feugiat sed consectetur sodales eleifend.<br />
                    Libero varius duis adipiscing dictum at amet.
                </p>
                <!-- <div class="w-[75%] my-8 flex gap-3 flex-wrap justify-center p-4 gap-5">
                    <span
                        class="rounded-md text-[#fff] hover:text-[#FBBC05] px-4 py-2 mx-2 text-[16px] font-[500] cursor-pointer"
                        style="background: rgb(0, 174, 240); background: linear-gradient(0deg, rgba(0, 174, 240, 1) 37%, rgba(123, 42, 255, 1) 100%)"
                        v-for="(hashtag, index) in hashtags"
                        :key="index"
                        >{{ hashtag }}</span
                    >
                </div> -->
                <div class="my-8">
                    <router-link
                        to="/request-service"
                        class="text-[#fff] w-[fit-content] z-3 font-[700] mt-30 bg-[#3361FF] px-4 py-4 rounded-[15px] border border-[#3361FF] hover:bg-transparent hover:text-[#3361FF] duration-300 cursor-pointer duration-300"
                    >
                        Hire Top AI and Software Developers
                    </router-link>
                </div>
            </div>
            <div>
                <img src="../assets/landing-page-logos/bpo.svg" alt="image" />
            </div>
        </div>
        <div class="w-full flex justify-center md:justify-start gap-5 px-[5%] my-4">
            <div class="flex gap-2 items-center">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#5D5FEF" />
                    <path d="M5 9L7.8 12L13 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p>Startup friendly</p>
            </div>
            <div class="flex gap-2 items-center">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#5D5FEF" />
                    <path d="M5 9L7.8 12L13 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p>Great Service</p>
            </div>
        </div>
    </div>
    <hr class="h-[5px] bg-[#F5F8FF]" />

    <div class="px-2 lg:px-12 w-full flex flex-col justify-start items-center gap-10 py-14">
        <h2 class="w-full text-center font-bold text-slate-700 text-base lg:text-xl">Our assessments are inspired by the following companies and more</h2>
        <Vue3Marquee :cloned="true" :pauseOnHover="false" :gradient="true" :gradient-color="'#fff'" gradientLength="30%">
            <img loading="lazy" decoding="async" class="mx-10 lg:mx-20 w-32 aspect-video object-contain mix-blend-color-burn" src="../assets/landing-page-logos/aws.svg" alt="Partner" />
            <img loading="lazy" decoding="async" class="mx-10 lg:mx-20 w-32 aspect-video object-contain mix-blend-color-burn" src="../assets/landing-page-logos/ey.svg" alt="Partner" />
            <img loading="lazy" decoding="async" class="mx-10 lg:mx-20 w-32 aspect-video object-contain mix-blend-color-burn" src="../assets/landing-page-logos/pwc.svg" alt="Partner" />
            <img loading="lazy" decoding="async" class="mx-10 lg:mx-20 w-32 aspect-video object-contain mix-blend-color-burn" src="../assets/landing-page-logos/kpmg.svg" alt="Partner" />
            <img loading="lazy" decoding="async" class="mx-10 lg:mx-20 w-32 aspect-video object-contain mix-blend-color-burn" src="../assets/landing-page-logos/deloitte.svg" alt="Partner" />
        </Vue3Marquee>
    </div>
</template>

<script>
import { Vue3Marquee } from "vue3-marquee";

export default {
    name: "OutSourceBanner",
    components: { Vue3Marquee },
    data() {
        return {
            hashtags: ["#HEALTHCARE", "#FINANCE", "#EDUCATION", "#ENGINEERING", "#SALES", "#CUSTOMER SERVICE", "#MARKETING", "#HUMAIN RESOURCES", "#HOSPITALITY", "#ADMINISTRATION", "#RETAIL"],
        };
    },
};
</script>
